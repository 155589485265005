.Menu{
    display: flex;
    width:70%;
    padding-bottom:14px ;
    color:white;
    font-size: 24px;
    justify-content: right;
    font-weight: bold;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0);
    font-family: 'Open Sans', sans-serif;
    font-family: 'VT323', monospace;
}
.dl-horizontal{
    padding:0px 14px;
    width: fit-content;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.dl-horizontal li{
    display: inline;
    margin: 0 18px 0 24px;
}

li{
    list-style:none;
}
.Menu a{
    font-size: 20px;
}

.Menu a:hover{
    color:rgb(163, 163, 163);
}

a{
    color:aliceblue;
    text-decoration: none;
}

.MenuAmburguer{
    display: none;
   
}

@media screen and (max-width:942px){
    .dl-horizontal{
        display: none;
    }
    .Menu{
        width: 100%;
    }
    .MenuAmburguer{
        width: 100vw;
        display:flex;
        position: absolute;
        left: 0;
        justify-content: right;
        flex-wrap: wrap;
    }
    .menu-suspenso{
        background-color: #150927;
        width: 100%;
        box-sizing: border-box;
        padding:8px;
        justify-content: left;

    }

    Button{
        margin:0 38px;
    }
}