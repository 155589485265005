.MostraGame{
    width: 100%;
    margin:150px 0 0 0;
    padding:8px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap:8px;
}

.imagens-do-jogo{
    display: flex;
    justify-content: space-around;
    width: 50%;
    min-width: 610px;
    gap:14px;
}

.imagem-maior{
    width:60%; 
    min-width: 420px;
    height:100%;
    background-image: url(../Assets/card.jpg);
    background-size: cover;
}

.imagens-menores{
    display:flex;
    width: 30%;
    justify-content:center;
    flex-wrap:wrap;
    gap:40px;
}

.img-menor-1{
    background-image: url(../Assets/card2.jpg);
}

.img-menor-2{
    background-image: url(../Assets/card3.jpg);
}

.img-menor-1, .img-menor-2{
    width: 100%;
    height: 210px;
    background-size: cover;
}

.textos-sobre-o-jogo{
    padding: 0;
    display:block;
    width: 48%;
    min-width: 350px;
}

.textos-sobre-o-jogo h2{
    margin: 0 0 14px 0;
    line-height: 0;
    font-size: 2em;
}
.textos-sobre-o-jogo a{
    color:rgb(204, 51, 102);
    text-decoration: underline;
}

.texto-cor-cinza-claro{
    color:#abbbbc;
}


.transparent-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: white;
    font-size: large;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media screen and (max-width:1300px){
    .MostraGame{
        padding: 0;
    }
    .imagens-do-jogo{
        width: 100%;
        margin-bottom: 150px;
    }
    .textos-sobre-o-jogo{
        width: 100%;
    }
    
}

@media screen and (max-width: 680px){
    .imagens-do-jogo{
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        min-width: 350px;
        flex-wrap: wrap;
        gap:24px;
    }
    .imagem-maior{
        width:60%; 
        min-width: 100%;
        height:400px;
        background-image: url(../Assets/card.jpg);
        background-size: cover;
    }
    .imagens-menores{
        width: 70%;
    }
}
