.MenuAmburguer{
    font-size: 16px;
    font-weight: 0;
    box-sizing: border-box;
}
.MenuAmburguer .image-button{
    margin-right: 50px;
    width: 80px;
    height: 80px;
    background-image: url('../Assets/menu_amburger.jpg');
    background-size: cover;
}
.menu-suspenso{
    padding: 14px;
    display: grid;
    gap:32px;
}