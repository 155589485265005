.App {
  position: relative;
  background-color: black;
  z-index: 4;
  box-sizing: border-box;
}

.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1220px;
}

.fundo-image-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 300px;
  background-size: cover;
  z-index: -1;
}

.cor-do-fundo-fundo {
  position: relative;
  padding: 14px;
  background-color: black;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-size: cover;
  z-index: 0;
}

h1 {
  color: azure;
  font-size: 62px;
  font-family: 'Open Sans', sans-serif;
  font-family: 'VT323', monospace;
}

p {
  font-size: 1em;
}

.fundo-negro {
  background-color: black;
}

.fonte-pixel {
  font-family: 'Open Sans', sans-serif;
  font-family: 'VT323', monospace;
}

.background {
  height: 250px;
  background-size: cover;
}

.texto-cor-cinza {
  color: rgb(87, 86, 86);
}

@media screen and (max-width: 1320px) {
  .fundo-image-top {
    display: none;
  }
}

/* Animação para os quadrados subirem de forma contínua */
@keyframes subirQuadrados {
  0% {
    transform: translateY(100vh); /* Começa fora da tela (em baixo) */
  }
  100% {
    transform: translateY(-100vh); /* Vai até fora da tela (em cima) */
  }
}

.fundo-pixelado {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  z-index: -1;
}

/* Estilo para os quadrados */
.quadrado {
  position: absolute;
  background-color: white;
  animation: subirQuadrados 10s linear infinite; /* Aplica a animação */
  opacity: 0.8;
}

/* Quadrado 1 */
.quadrado:nth-child(1) {
  left: 10%;
  width: 40px;
  height: 40px;
  animation-duration: 12s;
}

/* Quadrado 2 */
.quadrado:nth-child(2) {
  left: 30%;
  width: 20px;
  height: 20px;
  animation-duration: 8s;
}

/* Quadrado 3 */
.quadrado:nth-child(3) {
  left: 35%;
  width: 70px;
  height: 70px;
  animation-duration: 14s;
}

/* Quadrado 4 */
.quadrado:nth-child(4) {
  left: 70%;
  width: 100px;
  height: 100px;
  animation-duration: 10s;
}

/* Quadrado 5 */
.quadrado:nth-child(5) {
  left: 20%;
  width: 70px;
  height: 70px;
  animation-duration: 16s;
}

/* Quadrado 6 */
.quadrado:nth-child(6) {
  left: 40%;
  width: 70px;
  height: 70px;
  animation-duration: 20s;
}

/* Quadrado 7 */
.quadrado:nth-child(7) {
  left: 60%;
  width: 90px;
  height: 90px;
  animation-duration: 18s;
}

/* Quadrado 8 */
.quadrado:nth-child(8) {
  left: 80%;
  width: 40px;
  height: 40px;
  animation-duration: 22s;
}

/* Quadrado 9 */
.quadrado:nth-child(9) {
  left: 5%;
  width: 20px;
  height: 20px;
  animation-duration: 15s;
}

/* Quadrado 10 */
.quadrado:nth-child(10) {
  left: 15%;
  width: 65px;
  height: 65px;
  animation-duration: 12s;
}

/* Quadrado 11 */
.quadrado:nth-child(11) {
  left: 45%;
  width: 60px;
  height: 60px;
  animation-duration: 17s;
}

/* Quadrado 12 */
.quadrado:nth-child(12) {
  left: 85%;
  width: 40px;
  height: 40px;
  animation-duration: 19s;
}
