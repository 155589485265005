.DestaqueGame{
    margin: 0 auto;
    margin-top:60px;
    padding:0;
    width: 100%;
    color:white;
    display:flex;
    justify-content: left;
    align-items: flex-end;
    flex-wrap: wrap;
}
.game-nome{
    width: 40%;
    padding:14px;
    gap:32px;
}
.game-nome a{
    color:#3c3b3b;
}
.game-nome h1{
    font-size: 4em;
}

.Apoiar{
    background-color: #808080;
    margin-top: 40px;
    padding: 8px 24px;
    text-align: center;
    display: grid;
    align-items: center;
    border-radius: 50px;
    width: 97px;
    height: 45px;
}
.Apoiar a{
    color:#e7f0f6;
    font-size: 2em;
    display: block;
}
.Apoiar:hover{
    background-color: rgb(87, 86, 86);
    cursor: pointer;
}

.agrupa-moldura-e-detalhes{
    margin-top:24px;
    display: flex;
    background-color: black;
    align-items: flex-end;
    flex-wrap:wrap;
}
.Moldura-destaque-game{
    width:380px;
    height:520px;
    margin:0 24px;
    padding:32px;
    display: inline;
    border:5px solid #808080;
}
.image-card{
    width:400px;
    height:400px;
    background-image: url('../Assets/varcolaci.png');
    background-size: cover;
}
.Moldura-destaque-game h1{
    margin-top: 0;
    margin-bottom:0;
}
.Moldura-destaque-game p{
    margin-top: 0;
    margin-bottom:0;
}
.Detalhes{
    display: inline;
    padding:14px;
}
.Detalhes h2{
    font-size:2em;
    margin-bottom: 4px;
}
.Detalhes p{
    margin-top: 0;
}

@media screen and (max-width:1320px) {
    .DestaqueGame{
        display: grid;
    }
    .game-nome{
        width: 100%;
        gap:32px;
    }
    .Moldura-destaque-game{
        width:400px;
        height:518px;
    }
    .image-card{
        width:350px;
        height:350px;
    }
}

@media screen and (max-width: 750px) {
    .image-card{
        width:300px;
        height:300px;
    }
    .Moldura-destaque-game{
        width:300px;
        height:408px;
        padding:14px;
    }
    .game-nome{
        padding:14px;
        box-sizing: border-box;
    }
    
}

@media screen and (max-width: 360px) {
    .image-card{
        width:250px;
        height:250px;
    }
    .Moldura-destaque-game{
        width:250px;
        height:358px;
        padding:14px;
    }
    .game-nome{
        padding:14px;
        box-sizing: border-box;
    }
    
}
